import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useQuiz from "../../hooks/useQuiz";

const Question = () => {
  const {
    getQuizQuestion,
    createQuizQuestion,
    deleteQuizQuestion,
    UpdateQuizQuestion,
    getQuiz,
  } = useQuiz();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAllQuiz, setGetAllQuiz] = useState([]);
  const [getAllquizQuestions, setGetAllQuizQuestions] = useState([]);
  const [quizQuestionData, setQuizQuestionData] = useState([{
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correct_answer: "",
    coin: "",
  }]);

  const [quizId, setQuizId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearStateValues();
  };

  const handleEditQuizQuestion = (row) => {
    handleClickOpen();
    setIsEdit(true);
    clearStateValues();
  };

  const getQuizQuestionList = async () => {
    const getAllquizQuestions = await getQuizQuestion({});
    setGetAllQuizQuestions(getAllquizQuestions?.data?.data);
  };

  const handleSaveQuiz = async () => {
    setLoading(true);
    for (const ele of quizQuestionData) {
      await createQuizQuestion(ele);
    }
    clearStateValues();
    await getQuizQuestionList();
    setLoading(false);
    handleClose();
  };

  const handleUpdateQuiz = async () => {
    await UpdateQuizQuestion(quizQuestionData).then((res) => {
      if (res.data.status === 200) {
        getQuizQuestionList();
      }
    });

    clearStateValues();
    handleClose();
  };

  const handleOnChange = (event, index) => {
    const { name, value } = event.target;

    const updatedQuizQuestion = {
      ...quizQuestionData[index],
      'quiz_id': quizId,
      [name]: name === 'coin' ? +value : value,
    };

    const updatedQuizQuestionData = [...quizQuestionData];
    updatedQuizQuestionData[index] = updatedQuizQuestion;
    setQuizQuestionData(updatedQuizQuestionData);
  };



  const deleteQuizData = async (id) => {
    await deleteQuizQuestion(id).then((res) => {
      if (res.data.status === 200) {
        getQuizQuestionList();
      }
    });
  };

  const getCategoryList = async () => {
    const getAllQuiz = await getQuiz({});
    setGetAllQuiz(getAllQuiz?.data?.data);
  };

  const handleSwitchChange = async (data) => {
    if (data.isActive === true) {
      const body = {
        id: data.id,
        isActive: false,
      };

      await UpdateQuizQuestion(body).then((res) => {
        if (res.data.status === 200) {
          getQuizQuestionList();
        }
      });
    } else {
      const body = {
        id: data.id,
        isActive: true,
      };

      await UpdateQuizQuestion(body).then((res) => {
        if (res.data.status === 200) {
          getQuizQuestionList();
        }
      });
    }
  };

  useEffect(() => {
    getQuizQuestionList();
  }, []);

  useEffect(() => {
    if (open) {
      getCategoryList();
    }
  }, [open]);

  const clearStateValues = () => {
    setQuizQuestionData([{
      quiz_id: "",
      question: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      correct_answer: "",
      coin: "",
    }]);
  };

  return (
    <>
      <div className="page_header_main">
        <h1 className="page_title">Question</h1>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button variant="contained" onClick={handleClickOpen}>
            Add Question
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Option A</TableCell>
              <TableCell>Option B</TableCell>
              <TableCell>Option C</TableCell>
              <TableCell>Option D</TableCell>
              <TableCell>Correct Answer</TableCell>
              <TableCell>Coin</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllquizQuestions?.length > 0 &&
              getAllquizQuestions.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.question}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.optionA}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.optionB}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.optionC}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.optionD}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.correct_answer}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coin}
                  </TableCell>
                  <TableCell align="right" style={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditQuizQuestion(row)}
                      >
                        <EditIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteQuizData(row.id)}
                      >
                        <DeleteIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Question</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  hiddenLabel
                  labelId="quiz-select-label"
                  id="outlined-size-small"
                  value={quizId}
                  label="Quiz"
                  name="quiz_id"
                  onChange={(e) => setQuizId(e.target.value)}
                >
                  {getAllQuiz.length > 0 ? (
                    getAllQuiz.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No quizzes available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>


            {/* {new Array(questionCount).fill(null).map((_, index) => { */}
            {quizQuestionData.map((ele, index) => {
              return <>

                <Grid item xs={12}>
                  <InputLabel className="input_label">Question</InputLabel>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.question}
                    onChange={(e) => handleOnChange(e, index)}
                    name="question"
                  />
                </Grid>


                <Grid item xs={6} style={{ position: "relative" }}>
                  <InputLabel className="input_label">Option A</InputLabel>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.optionA}
                    onChange={(e) => handleOnChange(e, index)}
                    name="optionA"
                  />
                  <Checkbox
                    style={{ position: "absolute", top: "50%", right: "2px" }}
                    value={ele.optionA}
                    name="correct_answer"
                    onChange={(e) => handleOnChange(e, index)}
                    checked={ele.optionA.length && ele.optionA === ele.correct_answer}
                  />
                </Grid>


                <Grid item xs={6} style={{ position: "relative" }}>
                  <InputLabel className="input_label">Option B</InputLabel>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.optionB}
                    onChange={(e) => handleOnChange(e, index)}
                    name="optionB"
                  />
                  <Checkbox
                    style={{ position: "absolute", top: "50%", right: "2px" }}
                    value={ele.optionB}
                    name="correct_answer"
                    onChange={(e) => handleOnChange(e, index)}
                    checked={ele.optionB.length && ele.optionB === ele.correct_answer}

                  />
                </Grid>


                <Grid item xs={6} style={{ position: "relative" }}>
                  <InputLabel className="input_label">Option C</InputLabel>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.optionC}
                    onChange={(e) => handleOnChange(e, index)}
                    name="optionC"
                  />
                  <Checkbox
                    style={{ position: "absolute", top: "50%", right: "2px" }}
                    value={ele.optionC}
                    name="correct_answer"
                    onChange={(e) => handleOnChange(e, index)}
                    checked={ele.optionC.length && ele.optionC === ele.correct_answer}

                  />
                </Grid>


                <Grid item xs={6} style={{ position: "relative" }}>
                  <InputLabel className="input_label">Option D</InputLabel>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.optionD}
                    onChange={(e) => handleOnChange(e, index)}
                    name="optionD"
                  />
                  <Checkbox
                    style={{ position: "absolute", top: "50%", right: "2px" }}
                    value={ele.optionD}
                    name="correct_answer"
                    onChange={(e) => handleOnChange(e, index)}
                    checked={ele.optionD.length && ele.optionD === ele.correct_answer}

                  />
                </Grid>


                <Grid item xs={12}>
                  <InputLabel className="input_label">Coin</InputLabel>
                  <TextField
                    hiddenLabel
                    type="number"
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: "100%", minWidth: "450px" }}
                    value={ele.coin}
                    onChange={(e) => handleOnChange(e, index)}
                    name="coin"
                  />
                </Grid>

                {quizQuestionData.length > 1 ? <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <Button variant="contained" color="error" onClick={() => { }} disabled>
                      Remove Question
                    </Button>
                  </Box>
                </Grid> : null}

              </>
            })}



            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Button variant="contained" onClick={() => {
                  setQuizQuestionData([...quizQuestionData, {
                    question: "",
                    optionA: "",
                    optionB: "",
                    optionC: "",
                    optionD: "",
                    correct_answer: "",
                    coin: "",
                  }])
                }} disabled={loading}>
                  Add Question
                </Button>
                <Button variant="contained" color="error" onClick={() => { }} disabled={loading}>
                  Remove Question
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={isEdit === true ? handleUpdateQuiz : handleSaveQuiz}
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Question;
