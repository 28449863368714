import React from 'react'
import API from '../libs/webservice'
import { QUIZ_API } from '../libs/API'

const useQuiz = () => {
    const createCategory = async (body) => {
        return await API.post(QUIZ_API.CREATE_CATEGORY, body)
    }
    
    const getCategory = async () => {
        return await API.get(QUIZ_API.GET_CATEGORY)
    }
    
    const updateCategory = async (body) => {
        return await API.put(QUIZ_API.UPDATE_CATEGORY, body)
    }
    
    const deleteCategory = async (id) => {
        return await API.delete(`${QUIZ_API.DELETE_CATEGORY}/${id}`)
    }
    
    const createQuiz = async (body) => {
        return await API.post(QUIZ_API.CREATE_QUIZ, body)
    }
    
    const getQuiz = async () => {
        return await API.get(QUIZ_API.GET_QUIZ)
    }
    
    const getQuizByID = async (id) => {
        return await API.get(`${QUIZ_API.GET_QUIZ_BY_CATEGORY}${id}`)
    }
    
    const UpdateQuiz = async (body) => {
        return await API.put(QUIZ_API.UPDATE_QUIZ, body)
    }
    
    const deleteQuiz = async (id) => {
        return await API.delete(`${QUIZ_API.DELETE_QUIZ}/${id}`)
    }

    const createQuizQuestion = async (body) => {
        return await API.post(QUIZ_API.CREATE_QUIZ_QUESTION, body)
    }
    
    const getQuizQuestion = async () => {
        return await API.get(QUIZ_API.GET_QUIZ_QUESTION)
    }
    
    const getQuizQuestionByID = async (id) => {
        return await API.get(`${QUIZ_API.GET_QUIZ_QUESTION_BY_ID}${id}`)
    }
    
    const UpdateQuizQuestion = async (body) => {
        return await API.put(QUIZ_API.UPDATE_QUIZ_QUESTION, body)
    }
    
    const deleteQuizQuestion = async (id) => {
        return await API.delete(`${QUIZ_API.DELETE_QUIZ_QUESTION}/${id}`)
    }

    return { createCategory, getCategory, updateCategory, deleteCategory, createQuiz, getQuiz, getQuizByID, UpdateQuiz, deleteQuiz, createQuizQuestion, getQuizQuestion, getQuizQuestionByID, UpdateQuizQuestion, deleteQuizQuestion}
}

export default useQuiz