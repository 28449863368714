import { API_KEY, BLOG_ID } from "./Methods"

export const BLOG_API = {
    CREATE_DRAFT : '/v1/blog-service/create-draft-blog',
    GET_BLOG : '/v1/blog-service/get-blogs',
    GET_BLOG_BY_URL : '/v1/blog-service/get-single-blog',
    CREATE_BLOG : '/v1/blog-service/create-blog',
    CREATE_BLOG_CATEGORY : '/v1/blog-service/create-blog-category',
    GET_BLOG_CATEGORY : '/v1/blog-service/get-blog-category',
    ADD_BLOG_CATEGORY : '/v1/blog-service/create-blog-category',
}

export const QUIZ_API = {
    CREATE_CATEGORY : '/v1/quiz-service/category/create-quiz-category',
    GET_CATEGORY : '/v1/quiz-service/category/get-quiz-categories',
    UPDATE_CATEGORY : '/v1/quiz-service/category/update-quiz-category',
    DELETE_CATEGORY : '/v1/quiz-service/category/delete-quiz-category',
    CREATE_QUIZ : '/v1/quiz-service/quiz/create-quiz',
    GET_QUIZ : '/v1/quiz-service/quiz/get-quizzes',
    GET_QUIZ_BY_CATEGORY : '/v1/quiz-service/quiz/get-quizzies-by-category?',
    UPDATE_QUIZ : '/v1/quiz-service/quiz/update-quiz',
    DELETE_QUIZ : '/v1/quiz-service/quiz/delete-quiz',
    CREATE_QUIZ_QUESTION : '/v1/quiz-service/quiz/create-question',
    GET_QUIZ_QUESTION : '/v1/quiz-service/quiz/get-questions',
    GET_QUIZ_QUESTION_BY_ID : '/v1/quiz-service/quiz/get-question-by-quiz',
    DELETE_QUIZ_QUESTION : '/v1/quiz-service/quiz/delete-question',
    UPDATE_QUIZ_QUESTION : '/v1/quiz-service/quiz/update-question',
}

export const IMAGE_API = {
    UPLOAD_IMAGE: '/v1/image-service/upload'
}

export const BLOGGER = {
    GET_LIST: `https://blogger.googleapis.com/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}&fetchImages=true&fetchBodies=false`,
    GET_SINGLE : `https://blogger.googleapis.com/v3/blogs/${BLOG_ID}/posts/bypath?key=${API_KEY}`
}

export const POLL_API = {
    DELETE_POLL: '/v1/poll-service/delete-poll',
}

