
export const DRAFTKEY = "DRAFTKEY"

export class Storege{
    static GET(key){
        return localStorage.getItem(key)
    }
    static SET(key, value){
        return localStorage.setItem(key, value)
    }
    static REMOVE(key){
        return localStorage.removeItem(key)
    }
} 


export const Platforms = ['thorient', 'belbytes', 'downloader', 'finance'];
export const API_KEY = "AIzaSyA4u_vRD3Ex5hgyKZM41cPorXGD3shpHTs";
export const BLOG_ID = "6418287245148204862";
export const imageTypes = { blog: 'blog', option: 'option', quiz: 'quiz' }