import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useQuiz from "../../hooks/useQuiz";
import useImage from "../../hooks/useImage";
import { imageTypes } from "../../libs/Methods";
import { imageURL } from "../../libs/webservice";
import DeleteIcon from "@mui/icons-material/Delete";

const QuizManagement = () => {
  const { getQuiz, createQuiz, deleteQuiz, UpdateQuiz, getCategory } =
    useQuiz();
  const { uploadImage } = useImage();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAllCategorys, setGetAllCategorys] = useState([]);
  const [getAllquizs, setGetAllQuizs] = useState([]);
  const [quizData, setQuizData] = useState({
    title: "",
    id: "",
    thumbnail: "",
    coin: "",
    category_id: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setQuizData({
      title: "",
      id: "",
      thumbnail: "",
      coin: "",
    });
  };

  const handleEditQuiz = (row) => {
    handleClickOpen();
    setIsEdit(true);
    setQuizData({
      title: row.title,
      id: row.id,
      thumbnail: row.thumbnail,
      coin: row.coin,
      category_id: row.category_id,
    });
  };

  const getQuizList = async () => {
    const getAllQuizs = await getQuiz({});
    setGetAllQuizs(getAllQuizs?.data?.data);
  };

  const handleSaveQuiz = async () => {
    await createQuiz(quizData).then((res) => {
      if (res.data.status === 200) {
        getQuizList();
      }
    });

    setQuizData({});
    handleClose();
  };

  const handleUpdateQuiz = async () => {
    const body = {
      id: quizData.id,
      coin: quizData.coin,
      title: quizData.title,
      thumbnail: quizData.thumbnail,
      category_id: quizData.category_id,
    };

    await UpdateQuiz(body).then((res) => {
      if (res.data.status === 200) {
        getQuizList();
      }
    });

    setQuizData({
      title: "",
      id: "",
      thumbnail: "",
      coin: "",
    });
    handleClose();
  };

  const handleOnChange = async (event) => {
    if (event.target.name === "thumbnail") {
      const res = await uploadImage(event.target.files[0], imageTypes.quiz);
      setQuizData({
        ...quizData,
        [event.target.name]: imageURL + res.data.url,
      });
    } else {
      setQuizData({
        ...quizData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const deleteQuizData = async (id) => {
    await deleteQuiz(id).then((res) => {
      if (res.data.status === 200) {
        getQuizList();
      }
    });
  };

  const getCategoryList = async () => {
    const getAllCategory = await getCategory({});
    setGetAllCategorys(getAllCategory?.data?.data);
  };

  const handleSwitchChange = async (data) => {
    if (data.isActive === true) {
      const body = {
        id: data.id,
        isActive: false,
      };

      await UpdateQuiz(body).then((res) => {
        if (res.data.status === 200) {
          getQuizList();
        }
      });
    } else {
      const body = {
        id: data.id,
        isActive: true,
      };

      await UpdateQuiz(body).then((res) => {
        if (res.data.status === 200) {
          getQuizList();
        }
      });
    }
  };

  useEffect(() => {
    getQuizList();
  }, []);

  useEffect(() => {
    if (open) {
      getCategoryList();
    }
  }, [open]);

  return (
    <>
      <div className="page_header_main">
        <h1 className="page_title">Quiz</h1>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button variant="contained" onClick={handleClickOpen}>
            Add Quiz
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Icon</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Coin</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllquizs?.length > 0 &&
              getAllquizs.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <img src={row.thumbnail} width="35px" height="35px" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coin}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Switch
                      name="isActive"
                      //   onChange={() => handleSwitchChange(row)}
                      onClick={() => handleSwitchChange(row)}
                      defaultChecked={row.isActive}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right" style={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditQuiz(row)}
                      >
                        <EditIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteQuizData(row.id)}
                      >
                        <DeleteIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Quiz</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className="input_label">Title</InputLabel>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="medium"
                sx={{ width: "100%", minWidth: "450px" }}
                value={quizData.title}
                onChange={handleOnChange}
                name="title"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categori ID
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={quizData.category_id}
                  label="Categori ID"
                  name="category_id"
                  onChange={handleOnChange}
                >
                  {getAllCategorys.length > 0
                    ? getAllCategorys.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="input_label">Coin</InputLabel>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="medium"
                sx={{ width: "100%", minWidth: "450px" }}
                value={quizData.coin}
                onChange={handleOnChange}
                name="coin"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="input_label">Thumbnail</InputLabel>
              <div className="image_upload" style={{ padding: "0px" }}>
                <label htmlFor="image_upload">
                  {quizData?.thumbnail ? (
                    <img
                      src={quizData?.thumbnail}
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <AddPhotoAlternateOutlinedIcon
                        style={{ color: "#cccccc" }}
                      />
                      <p>Upload</p>
                    </>
                  )}
                </label>
                <input
                  hidden
                  id="image_upload"
                  type="file"
                  name="thumbnail"
                  onChange={handleOnChange}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={isEdit === true ? handleUpdateQuiz : handleSaveQuiz}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuizManagement;
