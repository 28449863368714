import { Button, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleLogin = () => {
    if (username == "belbytes@gmail.com" && password == "Thorient@7722") {  
      localStorage.setItem('dGVzdCBwYXNzd29yZAo!=', generateStaticKey())
      navigate('/dashboard')
    } else {
      setErrorMessage('Please enter a valid username and password');
    }
  };

  const generateStaticKey = () => {
    // Fixed prefix
    const prefix = generateRandomString(8) + '_';
    
    // Current timestamp
    const timestamp = Math.floor(Date.now() / 1000).toString(); // Unix timestamp in seconds

    // Random characters
    const randomChars = generateRandomString(8);

    // Combine elements
    const keyData = prefix + timestamp + randomChars;

    return keyData;
  }

  const generateRandomString = (length) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "#d9edff" }}>
      <div style={{ backgroundColor: "#FFF", padding: "20px 26px", display: "flex", flexDirection: "column", width: "30%", borderRadius: "20px", boxShadow: '1px 2px 9px #fffafa', textAlign: "center" }}>
        <h2>Login</h2>
        <TextField onChange={(e) => setUsername(e.target.value)} value={username} id="outlined-basic" label="User Name \ Email" variant="outlined" margin="dense" />
        <TextField type='password' onChange={(e) => setPassword(e.target.value)} value={password} id="outlined-basic" label="Password" variant="outlined" margin="dense" />
        <p className='error-message'>{errorMessage}</p>
        <div style={{ marginTop: "20px" }}>
          <Button variant="contained" size='large' onClick={() => handleLogin()}>Login</Button>
        </div>

      </div>
    </div>
  )
}

export default Login