import React from "react";
import PropTypes from "prop-types";
import { AppBar, Box, makeStyles, Tab, Tabs, Typography } from "@mui/material";
import { SwipeableDrawer } from "@mui/material";
import { useTheme } from "styled-components";
import Category from "./Category";
import QuizManagement from "./QuizManagement";
import Question from "./Question";
// import { makeStyles, useTheme } from '@material-ui/core/styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: 500,
//   },
// }));

const Quiz = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Category" {...a11yProps(0)} />
          <Tab label="Quiz" {...a11yProps(1)} />
          <Tab label="Question" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Category />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <QuizManagement />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Question />
      </TabPanel>
    </>
  );
};

export default Quiz;
