import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link, useLocation } from 'react-router-dom';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PollIcon from '@mui/icons-material/Poll';
import QuizIcon from '@mui/icons-material/Quiz';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Sidebar = ({ open, closeSidebar }) => {

    const theme = useTheme();
    const location = useLocation()

    const sidebarManu = [
        {
            name: 'Dashboard',
            icon: <DashboardIcon style={{ color: location.pathname === '/dashboard' ? '#FFF' : '#000' }} />,
            pathname: '/dashboard'
        },
        {
            name: 'Blog',
            icon: <NewspaperIcon style={{ color: location.pathname === '/blog' ? '#FFF' : '#000' }} />,
            pathname: '/blog'
        },
        {
            name: 'About',
            icon: <InfoOutlinedIcon style={{ color: location.pathname === '/about' ? '#FFF' : '#000' }} />,
            pathname: '/about'
        },
        {
            name: 'Privacy',
            icon: <SecurityOutlinedIcon style={{ color: location.pathname === '/privacy' ? '#FFF' : '#000' }} />,
            pathname: '/privacy'
        },
        {
            name: 'T&C',
            icon: <GavelOutlinedIcon style={{ color: location.pathname === '/tearms-and-conditions' ? '#FFF' : '#000' }} />,
            pathname: '/tearms-and-conditions'
        },
        {
            name: 'Blogger',
            icon: <InfoOutlinedIcon style={{ color: location.pathname === '/about' ? '#FFF' : '#000' }} />,
            pathname: '/blogger'
        },
        {
            name: 'Poll',
            icon: <PollIcon style={{ color: location.pathname === '/about' ? '#FFF' : '#000' }} />,
            pathname: '/poll'
        },
        {
            name: 'Quiz',
            icon: <QuizIcon style={{ color: location.pathname === '/quiz' ? '#FFF' : '#000' }} />,
            pathname: '/quiz'
        },
    ]

    return (
        <>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader  style={{gap:'30px'}}>
                    <h1 className='logo_text'>Thorient</h1>
                    <IconButton onClick={closeSidebar}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {sidebarManu.map((item, index) => (
                        <Link to={item.pathname} className={`${location.pathname === item.pathname ? 'active_sidebar_link' : 'sidebar_link'}`} >
                            <div className='inner_link' style={{ justifyContent: open ? 'start' : 'center', backgroundColor: location.pathname === item.pathname ? '#3e54ab' : '#fff' }}>
                                {item.icon} <span style={{ display: open ? 'block' : 'none', color: location.pathname === item.pathname ? '#FFF' : '#000' }}>{item.name}</span>
                            </div>
                        </Link>
                    ))}
                </List>
            </Drawer>
        </>
    )
}

export default Sidebar