import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useQuiz from "../../hooks/useQuiz";
import useImage from "../../hooks/useImage";
import { imageTypes } from "../../libs/Methods";
import { imageURL } from "../../libs/webservice";
import DeleteIcon from "@mui/icons-material/Delete";

const Category = () => {
  const { getCategory, createCategory, deleteCategory, updateCategory } =
    useQuiz();
  const { uploadImage } = useImage();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAllCategorys, setGetAllCategorys] = useState([]);
  const [categorysData, setCategorysData] = useState({
    name: "",
    id: "",
    thumbnail: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setCategorysData({
      name: "",
      id: "",
      thumbnail: "",
    });
  };

  const handleEditCategory = (row) => {
    handleClickOpen();
    setIsEdit(true);
    setCategorysData({
      name: row.name,
      id: row.id,
      thumbnail: row.thumbnail,
    });
  };

  const getCategoryList = async () => {
    const getAllCategory = await getCategory({});
    setGetAllCategorys(getAllCategory?.data?.data);
  };

  const handleSaveCategory = async () => {
    const body = {
      name: categorysData.name,
      thumbnail: categorysData.thumbnail,
    };

    await createCategory(body).then((res) => {
      if (res.data.status === 200) {
        getCategoryList();
      }
    });

    setCategorysData({
      name: "",
      id: "",
      thumbnail: "",
    });
    handleClose();
  };

  const handleUpdateCategory = async () => {
    const body = {
      id: categorysData.id,
      name: categorysData.name,
      thumbnail: categorysData.thumbnail,
    };

    await updateCategory(body).then((res) => {
      if (res.data.status === 200) {
        getCategoryList();
      }
    });

    setCategorysData({
      name: "",
      thumbnail: "",
    });
    handleClose();
  };

  const handleOnChange = async (event) => {
    if (event.target.name === "thumbnail") {
      const res = await uploadImage(event.target.files[0], imageTypes.quiz);
      setCategorysData({
        ...categorysData,
        [event.target.name]: imageURL + res.data.url,
      });
    } else {
      setCategorysData({
        ...categorysData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const deleteCategoryData = async (id) => {
    await deleteCategory(id).then((res) => {
      if (res.data.status === 200) {
        getCategoryList();
      }
    });
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <>
      <div className="page_header_main">
        <h1 className="page_title">Category</h1>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* <Button variant="contained" onClick={() => {}}>
            Add Quiz
          </Button> */}
          <Button variant="contained" onClick={handleClickOpen}>
            Add Category
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Icon</TableCell>
              <TableCell>Category</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllCategorys?.length > 0 &&
              getAllCategorys.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <img src={row.thumbnail} width="35px" height="35px" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right" style={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        width: "fit-content",
                        marginLeft: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditCategory(row)}
                      >
                        <EditIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#3e54ab",
                          width: "40px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          padding: "8px 0px",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteCategoryData(row.id)}
                      >
                        <DeleteIcon style={{ color: "#FFFFFF" }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Category</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className="input_label">Category</InputLabel>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="medium"
                sx={{ width: "100%", minWidth: "450px" }}
                value={categorysData.name}
                onChange={handleOnChange}
                name="name"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="input_label">Thumbnail</InputLabel>
              <div className="image_upload" style={{ padding: "0px" }}>
                <label htmlFor="image_upload">
                  {categorysData?.thumbnail ? (
                    <img
                      src={categorysData?.thumbnail}
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <>
                      <AddPhotoAlternateOutlinedIcon
                        style={{ color: "#cccccc" }}
                      />
                      <p>Upload</p>
                    </>
                  )}
                </label>
                <input
                  hidden
                  id="image_upload"
                  type="file"
                  name="thumbnail"
                  onChange={handleOnChange}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={
              isEdit === true ? handleUpdateCategory : handleSaveCategory
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Category;
