import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './style/Blog.css'
import { Storege } from './libs/Methods';
import { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {

  useEffect(() => {
    const platform = Storege.GET("platform");
    if (platform == undefined || platform == null) {
      Storege.SET("platform", "thorient")
    }
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3E54AC',
      },
      secondary: {
        main: '#ecf2ff'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
